import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import { useEffect, useState } from "react";
import { Loader } from "./components/loader";
import MainTeam from "./pages/team";

function App() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [loader]);

  if (loader === true) {
    return <Loader />;
  }

  return (
    <div className="App w-[100%] overflow-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<MainTeam />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

const Slider = () => {
  const data = [
    {
      thumbnail:
        "https://images.unsplash.com/photo-1521587760476-6c12a4b040da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
    },
    {
      thumbnail:
        "https://images.unsplash.com/photo-1516026672322-bc52d61a55d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2872&q=80",
    },
    {
      thumbnail:
        "https://images.unsplash.com/photo-1568216681201-1edacc6c8048?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2233&q=80",
    },
    {
      thumbnail:
        "https://blogger.googleusercontent.com/img/a/AVvXsEhWIyePafTaVWS5ckysYnHA03jU4WPGt_fQU4v63JIbehAQiayfbkEICcwjLa1B1TbcV7kt3uBEzCvN-dzoRCkZYtJWAIcKOyMrjnU80MG-HH4dpKzppasx8EAtbfLZVbxrzeT7bf5Y3fNRSb65Ny-xcr0SuQpJ-PKbQL4QJ5tQXibRuzcVUDrKoN3b=s16000",
    },
  ];
  return (
    <div className=" h-screen w-screen relative">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper w-full h-full"
      >
        {data.map((item, i) => (
          <SwiperSlide
            key={i}
            style={{
              backgroundImage: `url(${item.thumbnail})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></SwiperSlide>
        ))}
      </Swiper>
      <div
        className="w-full h-full  absolute top-0  flex justify-center"
        style={{
          background:
            "linear-gradient(to right, white, rgba(255,255,255,.7), rgba(0,0,0,0) )",
          zIndex: 2,
        }}
      >
        <div className="w-3/4 h-full flex justify-center flex-col items-start">
          <div
            className="lg:text-5xl text-4xl font-bold text-blue-900 bold text-left "
            // style={{
            //   color: "#f09821",
            // }}
          >
            Combining Experience,
            <br />
            Knowledge and
            <br />
            Dedication
          </div>
          <div className="my-2 text-blue-900">
            &#169; 2022 Msando Law Office
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;

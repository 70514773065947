import React from "react";
import NavBar from "../components/navBar";

import AOS from "aos";
import "./../../node_modules/aos/dist/aos.css";

const MainTeam = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AOS.init({
    duration: 1000,
  });

  const personData = [
    {
      name: "CLAUDIO MSANDO",
      title: "Founder and Advocate of the High Court of Tanzania",
      left: true,
      image: "../team/1.jpeg",
      content: (
        <div>
          Claudio Msando, being the Founder of the firm has an in-depth
          knowledge and experience of Tanzanian Corporate Management, Banking
          and Finance, Real Estate, Litigation, Alternative Dispute Resolution,
          Trust and Wealth Management, Security Perfection and Specialized
          Litigation. With his particular experience in his areas of practice,
          Claudio Msando provides practical and responsive advice to both local
          and international clients in Tanzania.
          <br />
          <br />
          That, prior of founding the firm, Mr. Msando was an Associate at
          Mawalla Advocates, where he gained a vast knowledge on Corporate &
          Commercial Law, Labor, Employment and Immigration Law, Corporate
          Finance Law, Real Estate and Conveyancing, Specialized Litigation,
          Alternative Dispute Resolution and Banking and Finance and Security
          Perfection.
        </div>
      ),
      number: "712 567 110",
      email: "Claudio.msando@msandolaw.co.tz",
      address_title: "Advocate and Founder",
    },
    {
      name: "SALIM OMARY SALIM",
      title: "Advocate and Senior Associate",
      image: "../team/2.jpeg",
      left: false,
      content: (
        <div>
          Representation of clients to the Court of Laws, Quasi-Judicial,
          Tribunal and other legal forums. Liaising with different organization,
          authorities, embassies and Firms in different dispute resolutions
          aspects. <br />
          <br />
          Insurance Claim handling and analysis. Monitoring and manage cases,
          involvement and ensuring fair settlement of claim, liaising with a
          range of insurance, adjustor, and assessor officer in handling claims,
          Prepare Contracts and other legal documents, do all compliance
          matters.
        </div>
      ),
      number: "652 236 005",
      email: "salim.kitogo@msandolaw.co.tz",
      address_title: "Advocate and Senior Associate",
    },
    {
      name: "JUSTINE PHILEMON MBANGA",
      title: "Advocate and Head of Litigation Department",
      image: "../team/3.png",
      left: true,
      content: (
        <div>
          Specialized litigation and alternative dispute resolution; mining,
          utility and energy; capital markets and securities; and taxation.{" "}
          <br />
          <br />
          He brings to the team specialized litigation, negotiation and
          arbitration skills. As the part of the litigation department, Mr.
          Justine has successfully led the litigation team in undertaking
          various legal disputes with respect to the litigation matters.
        </div>
      ),
      number: "762 015 224",
      email: "justine.mbanga@msandolaw.co.tz",
      address_title: "Advocate and Head of Litigation",
    },
    {
      name: "ARAFA  SOLLO",
      title: "Associate and Head of Corporate Department",
      image: "../team/4.jpeg",
      left: false,
      content: (
        <div>
          Corporate, Banking and Finance; Insurance; Social Security;
          Securities; and Trademark Portfolio management, including prosecuting
          trademark applications and handling oppositions <br />
          <br />
          Dealing with general corporate matters in both Tanzania mainland and
          Zanzibar. Advising on and setting up an appropriate forms of business
          entity for transactions, and on the legal framework for the proposed
          business activity. Contracts and other legal documents, do all
          compliance matters.
          <br />
          <br />
          Dealing with regulatory compliances, such as securing business
          license, and filling of returns in relation to various forms of
          business activity and commercial transactions assisting clients in
          Merger and Acquisitions, also structuring of transactions such as
          share Transfer, sale of business and assets sale; <br />
          <br />
          Experienced in immigration to advise companies on immigration
          procedures and assist their employees meet immigration requirements.
        </div>
      ),
      number: "620 836 185",
      email: "arafa.sollo@msandolaw.co.tz",
      address_title: "Associate and Head of Corporate",
    },
    {
      name: "JOLYCE FRANCIS",
      title: "Lawyer and Head of Administration ",
      image: "../team/5.jpeg",
      left: true,
      content: (
        <div>
          Drafting of contracts and various legal documentations. Refine the
          legal operations model to increase effectiveness of legal services,
          develop accurate forecasting and reporting of legal spend, Optimize
          the legal operating model and structure; <br />
          <br />
          Understanding the commercial value of fulfilling compliance deadlines,
          keeping legal risks low and managing information confidentially.
          <br />
          <br />
          Ensuring legal affairs of the firm are handled appropriately; provide
          legal support to the operations of the firm and office administration
          at large.
        </div>
      ),
      number: "744 349 915",
      email: "jolyce.francis@msandolaw.co.tz",
      address_title: "Lawyer and Head of Administration",
    },
    {
      name: "AGNESS S. NAGABONA",
      title: "Lawyer and Head of Media & Tech Department",
      image: "../team/6.jpeg",
      left: false,
      content: (
        <div>
          Skilled in International Trade and Investment laws, Banking law,
          Alternative Dispute Resolution, Tax law, Labour and Employment Laws.{" "}
          <br />
          <br />
          Making, preparing, updating and publish legal articles on website and
          social platforms;
          <br />
          <br />
          Educating and providing legal seminar to media on legal matters and
          policies, dealing with legal questions, potential liabilities and
          policy option using oral or written platforms, legal analysts and
          applying solutions to a specific legal issue; <br />
          <br />
          Improving processes and legal technology implementation, analytics and
          other digitalization strategies. Sharing knowledge to improve
          planning, risk management, operational efficiency and self-reliance to
          the firm and align with the firm’s goals.
        </div>
      ),
      number: "657 958 608",
      email: "agness.nagabona@msandolaw.co.tz",
      address_title: "Lawyer and Head of Media and Tech",
    },
    {
      name: "FATUMA MASHAURI",
      title: "Administrative Assistant",
      image: "../team/7.jpeg",
      left: true,
      content: (
        <div>
          Provision of administrative support activities to ensure efficient
          office operation: accurate and update records, files and database and
          maintaining contact lists. Submitting and reconciling expense reports
          and providing general support to visitors.
          <br />
          Assist in preparing scheduled reports and presentations with
          statistical data as assigned. Maintain office calendar and schedule
          appointments and update office polices as needed. Prepare and monitor
          invoices and update administrative systems to make them more
          efficient.
        </div>
      ),

      number: "774 295 146",
      email: "fatuma.mashauri@msandolaw.co.tz",
      address_title: "Administration Assistant",
    },
    {
      name: "REHEMA KUSEKWA",
      title: "Legal Officer",
      image: "../team/8.jpeg",
      left: false,
      content: (
        <div>
          Experienced in corporate and capable of taking on paralegal
          responsibilities including legal documentations and review, ensuring
          accurate legal database management. Organizing case file and preparing
          litigation bundles, alongside being able to transcribe and synthesize
          key data from attending inquests and accurately new client information
          to pass on appropriate and relevant legal knowledge.
        </div>
      ),
      number: "789 580 504",
      email: "rehema.kusekwa@msandolaw.co.tz",
      address_title: "Legal officer",
    },
  ];
  return (
    <div className="grid grid-col-12 mt-20 bg-[#0e1438]">
      <NavBar away={true} />
      <div
        data-aos="fade-up"
        className="uppercase text-[#ffbd43] lg:text-5xl md:text-3xl xl:text-6xl text-2xl py-5  pl-4 md:pl-0 md:col-start-2 md:col-span-10 col-span-12"
      >
        CORE TEAM
      </div>
      <div className=" text-lg   col-span-12 ">
        {personData.map((item) => (
          <Person
            left={item.left}
            name={item.name}
            content={item.content}
            title={item.title}
            image={item.image}
            address_title={item.address_title}
            number={item.number}
            email={item.email}
          />
        ))}
      </div>
    </div>
  );
};

export default MainTeam;

const Person = ({
  left,
  name,
  content,
  title,
  image,
  address_title,
  number,
  email,
}) => {
  return (
    <div>
      {left ? (
        <div className="p-10 ">
          <div className="my-4 text-[#ffbd43] text-left" data-aos="zoom-out">
            <b>
              {name} || {title}
            </b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="md:w-2/3 w-full md:pr-4 flex flex-col items-start">
              <div
                className=" text-[#ffbd43]"
                align="justify"
                data-aos="fade-right"
              >
                {content}
              </div>
              <div className="text-left pt-10 text-white">
                {address_title}
                <br />
                FayKat Tower - Near Airtel Morrocco
                <br />
                9th Floor, Plot No: 236-238
                <br />
                Ali Hassan Mwinyi Road, Block 41
                <br />
                P. O. Box 24468
                <br />
                Dar Es Salaam, Tanzania
                <br />
                <br />
                T. +255 {number} <br />
                E. {email}
              </div>
            </div>
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-left"
            >
              <img src={image} alt="pic" className=" w-full  object-cover" />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #f5f5f5)",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-10  bg-[#383c6c] ">
          <div className="my-4 text-[#ffbd43]  text-right" data-aos="zoom-out">
            <b>
              {name}|| {title}
            </b>
          </div>
          <div className="flex flex-wrap md:flex-nowrap">
            <div
              className="relative md:w-1/3 w-full  px-2 md:pl-2 md:pr-0"
              data-aos="fade-right"
            >
              <img src={image} alt="pic" className=" object-cover" />
              <div
                className="absolute bottom-0 w-full md:pl-2 px-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0), #dbeafe)",
                }}
              />
            </div>
            <div className="md:w-2/3 w-full md:pl-4 flex flex-col items-end">
              <div
                className=" text-[#ffbd43]"
                align="justify"
                data-aos="fade-right"
              >
                {content}
              </div>
              <div className="text-right pt-10 text-white">
                {address_title}
                <br />
                FayKat Tower - Near Airtel Morrocco
                <br />
                9th Floor, Plot No: 236-238
                <br />
                Ali Hassan Mwinyi Road, Block 41
                <br />
                P. O. Box 24468
                <br />
                Dar Es Salaam, Tanzania
                <br />
                <br />
                T. +255 {number} <br />
                E. {email}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React from "react";
import Footer from "../components/footer";
import NavBar from "../components/navBar";

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="flex flex-col items-center bg-black bg-opacity-5 ">
      <NavBar away={true} />
      <div className=" w-screen relative" style={{ height: 500 }}>
        <div className=" w-full h-full">
          <div
            style={{
              backgroundImage: `url("../bg.jpg")`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: "100%",
            }}
          />
        </div>
        <div
          className="w-screen h-full  absolute top-0  flex justify-center items-center"
          style={{
            background: `linear-gradient(to right, #0e1438, rgba(0,0,0,0) )`,
            zIndex: 2,
          }}
        >
          <div className="w-3/4  flex flex-wrap justify-between">
            <div className="w-1/3 h-full flex justify-center flex-col items-start mx-10 text-left ">
              <div className=" bold w-full flex flex-col items-center justify-center text-4xl py-8 text-white">
                ABOUT US
                <div
                  className="bg-blue-900 mt-3"
                  style={{
                    height: 1,
                    width: 100,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="w-full lg:px-28 py-8 my-6 px-4 bg-[#0e1438] text-yellow-600"
          align="justify"
        >
          Msando Law Office is a law firm based in Dar es Salaam, Tanzania,
          which was founded in 2022. Since its inception, the Firm has evolved
          from a general practice Law firm to a specialized Law firm with a wide
          range of interdisciplinary expertise, giving it an edge in resolving a
          variety of clients’ problems.
          <br />
          <br />
          In order to monitor the Firm’s performance and ensure top-notch
          services, we have internal management policies that guide each
          member’s conduct and performance. The policies are binding to the
          entire team as they form part of each employee’s employment agreement.
          <br />
          <br />
          The Firm also regulates its performance through holding periodic
          service review meetings with each client where all matters pertaining
          to client’s work assigned to us, performance and relationship with the
          respective client are addressed. Such periodic reviews allow the Firm
          and each client to make new targets and goals in furtherance of the
          client’s businesses.
          <br />
          <br />
          The firm has an adept team with vast experience and knowledge in the
          areas of practice that we have specialized on. Our team is committed
          to professionally service and create value to our clients. We take
          pride in our values and ethos of being upfront and honest in all our
          dealings.
          <br />
          <br />
          The firm has further established an effective communication with our
          clients. We strive to listen and understand our clients’ challenges
          and provide practical solutions in a timely manner.
          <br />
          <br />
          We devise strategic, tailored and long-term solutions to our clients.
          Every matter referred to us is reviewed on its uniqueness and
          individuality and solutions provided are specifically designed to
          solve it. We devise solutions which are long term and beneficial to
          the client’s business.
        </div>
      </div>
      <div className="flex flex-wrap  w-3/4 mt-20 pb-20">
        <div className="md:w-2/3 w-full ">
          <div
            className=" text-yellow-600 p-2"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl mb-4">Our Mission</div>
            <div d align="justify">
              To add value to every transaction through offering excellent and
              practical advice at a reasonable price. At Msando Law Office, we
              are aware that our clients are desirous of maximum return on their
              investments, and that includes investment in our legal services;
              being cognizant of this, we leverage on our highly knowledgeable
              people and modernized technology to work faster and more
              efficiently.
            </div>
          </div>
          <div className="h-10 w-1" />
          <div
            className=" text-yellow-600 p-2"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl mb-4">The Vision</div>
            <div align="justify">
              To build a strong team of trusted legal advisors for our clients
              which transcends generations. This team will be able to
              competently and consistently deliver a full suite of excellent and
              cost-effective legal services.
            </div>
          </div>
        </div>
        <div className="md:w-1/3 w-full flex justify-center">
          <div className="flex flex-col items-start p-4 bg-black bg-opacity-10 mx-4">
            <div className=" text-3xl mb-2 b">Our Customers…</div>
            <div className="flex flex-col items-start text-left ">
              <div>1. Individuals</div>
              <div>2. Sole and Joint Proprietors</div>
              <div>3. Public and private Companies</div>
              <div>4. Government Corporations, Institutions and affiliates</div>
              <div>5. Non-Government Organization and affiliates</div>
              <div>6. Investment banks and other financial institutions</div>
              <div>7. Private Public Partnerships</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;

import React from "react";

import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { ImTwitter } from "react-icons/im";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div
      className="grid grid-col-12 py-10  text-white"
      style={{
        background: "#0e1438",
      }}
    >
      <div className="w-screen flex justify-center">
        <Link to="/">
          <img src="one_logo.png" alt="" className=" h-20 -mt-2" />
        </Link>
      </div>
      <div className=" flex-wrap md:justify-center justify-start grid md:grid-cols-2 text-blue-900">
        <div className=" flex justify-center ">
          <div className="lg:ml-10 px-4 m-0 w-full text-left">
            <div className="mt-10 ">
              <div className="lg:text-lg  mb-5 text-sm text-white bold font-bold uppercase">
                Address
              </div>
              <div className="lg:text-lg  text-sm text-white">
                FayKat Tower - Near Airtel Morrocco <br />
                9th Floor, Plot No:236-238
              </div>
              <div className="lg:text-lg  text-sm text-white">
                Ali Hassan Mwinyi Road, Block 41 <br />
                P.O.BOX 24468
                <br />
                Dar es salaam - Tanzania
              </div>
              <div className="text-[#f19921] pt-14">
                <b>Working Hours:</b> <br />
                Monday-Friday
                <br /> 08:00 am-05:00 pm.
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end md:pr-20 px-4 text-right">
          <div>
            <div className="lg:text-lg  text-sm mt-16 text-white">
              <div className="lg:text-lg  text-sm mb-5 text-white bold font-bold uppercase">
                Contact
              </div>
              <a
                href="tel:0712567110"
                style={{
                  textDecoration: "none",
                }}
                className="lg:text-lg  text-sm text-white hover:text-red-700"
              >
                T. +255 712 567 110
              </a>
            </div>
            <div className="lg:text-lg  text-sm text-white">
              <a
                href="mailto:claudio.msando@msandolaw.co.tz"
                style={{
                  textDecoration: "none",
                }}
                className="lg:text-lg  text-sm text-white hover:text-red-700"
              >
                claudio.msando@msandolaw.co.tz
              </a>
            </div>
          </div>
        </div>

        <div className="w-screen flex justify-center mt-10">
          <div>
            <div className="text-2xl text-white font-medium">Let's Connect</div>
            <div className="flex justify-center">
              <a
                href="
                https://www.facebook.com/claudd__msando/"
                className="text-lg text-white mx-2 my-4 hover:text-red-700"
              >
                <FaFacebook size={30} />
              </a>
              <a
                href="https://www.instagram.com/claudd__msando/"
                className="text-lg text-white mx-2 my-4 hover:text-red-700"
              >
                <AiFillInstagram size={30} />
              </a>
              <a
                href="https://twitter.com/claudd__msando"
                className="text-lg text-white mx-2 my-4 hover:text-red-700"
              >
                <ImTwitter size={30} />
              </a>
              <a
                href="https://www.linkedin.com/claudd__msando/"
                className="text-lg text-white mx-2 my-4 hover:text-red-700"
              >
                <IoLogoLinkedin size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

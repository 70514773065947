import React from "react";

export const Loader = () => {
  return (
    <div
      className="w-screen h-screen flex items-center justify-center"
      style={{
        background: "#101425",
      }}
    >
      <img
        src="../loader.png"
        alt="logo"
        className=" animate-pulse"
        style={{
          width: 300,
        }}
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { CgMenuRightAlt } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const NavBar = () => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (expand) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [expand]);
  return (
    <div className="w-screen fixed top-0 bg-[#0e1438] py-3  z-10 flex sm:justify-start md:justify-between ">
      <NavLink
        className=" px-6 cursor-pointer  left-2"
        to="/"
        onClick={() => setExpand(false)}
      >
        <img src="../logo.png" alt="logo" className="w-36 " />
      </NavLink>

      {/* opening navigation button */}
      {!expand && (
        <div
          className=" sm:hidden cursor-pointer  absolute right-0 text-white mx-4"
          onClick={() => {
            setExpand(true);
          }}
        >
          <CgMenuRightAlt size={27} />
        </div>
      )}
      {/* opening navigation button */}

      {/* mobile navigations */}
      <div
        className={
          expand
            ? "flex flex-col transition-all duration-500 sm:hidden m-0 w-screen text-xl overflow-hidden h-screen items-center justify-center absolute top-0 text-black"
            : "h-0 flex overflow-hidden  bottom-0 transition-all duration-500 w-screen m-0 absolute top-0 delay-200"
        }
        style={{
          background: "#0e1438",
        }}
      >
        <div
          className={
            expand
              ? "flex flex-col transition-all duration-500 sm:hidden m-0 w-screen delay-200 text-xl overflow-hidden h-screen items-center justify-center absolute top-0  bg-white text-black"
              : "h-0 overflow-hidden bg-black bottom-0 transition-all duration-500 w-screen m-0 absolute top-0"
          }
        ></div>
        <div className="relative w-screen h-screen overflow-hidden  bg-white flex items-center justify-center">
          <div
            className="py-2 cursor-pointer mt-0 absolute top-0 right-4"
            onClick={() => {
              setExpand(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={
                expand
                  ? "h-10 w-10 rotate-0 font-thin transition-all duration-500 delay-200"
                  : "h-0 w-10 rotate-90"
              }
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="p-10">
            <NavLink
              className="flex text-3xl my-2 group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
              to="/"
              activeClassName="bg-red-500"
              onClick={() => setExpand(false)}
            >
              <div
                className={
                  expand
                    ? "  transition-all duration-500 delay-200 h-8 overflow-hidden"
                    : " block overflow-hidden"
                }
              >
                <div
                  className={
                    expand
                      ? "h-8 transition-all duration-500 translate-y-0 delay-200 overflow-hidden"
                      : "h-8 overflow-hidden -translate-y-10 "
                  }
                >
                  HOME
                </div>
              </div>
            </NavLink>
            <NavLink
              className="flex text-3xl my-2 group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
              to="/about"
              onClick={() => setExpand(false)}
            >
              <div
                className={
                  expand
                    ? "  transition-all duration-500 delay-[400ms] h-8 overflow-hidden"
                    : " block  overflow-hidden"
                }
              >
                <div
                  className={
                    expand
                      ? "h-8 transition-all duration-500 translate-y-0 delay-[400ms] overflow-hidden"
                      : "h-8 overflow-hidden -translate-y-10 "
                  }
                >
                  ABOUT
                </div>
              </div>
            </NavLink>
            <NavLink
              className="flex text-3xl my-2 group px-2 flex-col cursor-pointer group hover:text-red-500 h-10"
              to="/services"
              onClick={() => setExpand(false)}
            >
              <div
                className={
                  expand
                    ? "  transition-all duration-500 delay-[600ms]  h-8 overflow-hidden"
                    : " block  overflow-hidden"
                }
              >
                <div
                  className={
                    expand
                      ? "h-8 transition-all duration-500 translate-y-0 delay-[600ms] overflow-hidden"
                      : "h-8 overflow-hidden -translate-y-10 "
                  }
                >
                  PRACTICE AREAS
                </div>
              </div>
            </NavLink>

            <NavLink
              className="flex text-3xl my-2 group px-2 flex-col cursor-pointer group hover:text-red-500 h-10"
              to="/team"
              onClick={() => setExpand(false)}
            >
              <div
                className={
                  expand
                    ? "  transition-all duration-500 delay-[800ms] h-8 overflow-hidden"
                    : " block  overflow-hidden"
                }
              >
                <div
                  className={
                    expand
                      ? "h-8 transition-all  duration-500 translate-y-0 delay-[800ms] overflow-hidden"
                      : "h-8 overflow-hidden -translate-y-10 "
                  }
                >
                  CORE TEAM
                </div>
              </div>
            </NavLink>

            <a
              href="mailto:claudio.msando@msandolaw.co.tz"
              className="flex  right-2 -top-3 text-3xl group my-4 mx-4 flex-col cursor-pointer group text-black hover:text-blue-900 "
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
      {/* mobile navigations */}

      {/* desktop navigations */}
      <div className=" hidden sm:block text-black px-2 w-full mt-1 relative">
        <div className="flex items-center  justify-center">
          <NavLink
            className="flex text-sm group px-16 border-r flex-col cursor-pointer  text-white hover:text-yellow-600"
            to="/"
            // onClick={() => setExpanded(false)}
          >
            <div className="flex  group-hover:font-black">HOME </div>

            <div
              className="bg-yellow-600 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </NavLink>

          <NavLink
            className="flex text-sm group px-16 border-r flex-col cursor-pointer text- text-white hover:text-yellow-600 hover:"
            to="/about"

            // onClick={() => setExpanded(false)}
          >
            <div className="flex group-hover:font-black">ABOUT</div>
            <div
              className="bg-yellow-600 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </NavLink>

          <NavLink
            className="flex text-sm group px-16 border-r flex-col cursor-pointer group text-white hover:text-yellow-600 "
            to="/services"

            // onClick={() => setExpanded(false)}
          >
            <div className="flex group-hover:font-black">PRACTICE AREAS </div>
            <div
              className="bg-yellow-600 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </NavLink>

          <NavLink
            className="flex text-sm group mx-20 flex-col cursor-pointer group text-white hover:text-yellow-600 "
            to="/team"
            onClick={() => setExpand(false)}
          >
            <div className="flex group-hover:font-black">CORE TEAM </div>
            <div
              className="bg-yellow-600 w-0 transition-all group-hover:w-full"
              style={{
                height: 2,
              }}
            />
          </NavLink>
          <div className="border-l">
            <a
              href="mailto:claudio.msando@msandolaw.co.tz"
              className=" hover:bg-blue-900 border border-blue-900 p-2 rounded-xl text-sm group mx-4 flex-col cursor-pointer group text-white"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
      {/* desktop navigations */}
    </div>
  );
};

export default NavBar;

import React from "react";
import ContainerContent from "../components/container";
import Footer from "../components/footer";
import NavBar from "../components/navBar";
import Services from "../components/services";
import Slider from "../components/slider";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <NavBar away={false} />
      <Slider />
      <ContainerContent
        title1="We offer competent, experienced & knowledgeable service"
        link1="/about"
        content="We are highly experienced legal practitioners who provide comprehensive tailor-made legal solutions to fit our clients’ needs."
        round={false}
        color="black"
        thumbnail="https://images.unsplash.com/photo-1556745753-b2904692b3cd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1673&q=80"
      />

      <Services />

      <ContainerContent
        title1="Our Team"
        id="team"
        link1="/team"
        content="Our team consists of lawyers with expertise in all sectors of business law, committed to offering high - quality services."
        round={true}
        color="black"
        thumbnail="../team.jpeg"
      />

      <Footer />
    </div>
  );
};

export default Home;

import React from "react";
import Footer from "../components/footer";
import NavBar from "../components/navBar";

const Services = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="flex flex-col items-center bg-black bg-opacity-5 ">
      <NavBar away={true} />
      <div className=" w-screen relative" style={{ height: 500 }}>
        <div className=" w-full h-full">
          <div
            style={{
              backgroundImage: `url("../bg.jpg")`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: "100%",
            }}
          />
        </div>
        <div
          className="w-screen h-full  absolute top-0  flex justify-center items-center"
          style={{
            background: `linear-gradient(to right, #0e1438, rgba(0,0,0,0) )`,
            zIndex: 2,
          }}
        >
          <div className="w-3/4  flex flex-wrap justify-between">
            <div className="w-1/3 h-full flex justify-center flex-col items-start mx-10 text-left ">
              <div className=" bold w-full flex flex-col items-center justify-center text-4xl py-8 text-white">
                PRACTICE AREAS
                <div
                  className="bg-blue-900 mt-3"
                  style={{
                    height: 1,
                    width: 100,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full justify-center  pb-20">
        <div className=" md:w-[1200px]  hidden lg:flex h-full justify-center">
          <div className="flex flex-col items-start  mt-4 mb-16 rounded-xl">
            <div className=" text-3xl mb-2 text-left bold font-black">
              Overview of our services
            </div>
            <div className="" align="justify">
              We are uniquely positioned to provide fully integrated services
              across a broad array of practice areas. We pursue each matter with
              both diligence and creativity to achieve results
            </div>
          </div>
        </div>
        <div className=" w-full ">
          <div
            className=" text-yellow-600  px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              1.Corporate Governance & Commercial Drafting
            </div>
            <div align="justify">
              We advise on all matters of corporate law in Tanzania and
              commercial drafting to public and private companies, start-ups,
              emerging businesses and non-governmental organizations, sole
              proprietor, partnership, and trust. We specialized on drawing
              advice especially to private sectors on wide range of corporate
              legal affairs such as; securitization; due diligence; corporate
              investment; pension and employment issues; and tax management. We
              use our business acumen and industry experience to ensure that our
              corporate client’s function in accordance with the law for wealth
              maximization and realization of their other business objectives at
              large.
            </div>
            <div className="py-2 px-4 rounded-xl my-4 bg-yellow-500  text-left text-gray-700">
              Our team’s meticulous attention to detail ensure that all
              commercial contracts are drafted to protect our clients’ interests
              and reduce exposure to future disputes. Our clients are assisted
              to identify, manage and mitigate risks of corporate
              non-compliance. Our services include;
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  Incorporation of corporate and other business entities.
                  <br />
                </li>
                <li>Compliance and regulatory services.</li>
                <li>
                  Restructuring, divestitures and dissolution of entities.
                </li>
                <li>Corporate governance and due diligence.</li>
                <li>Drafting and review of commercial contracts.</li>
                <li>
                  Audit and regularization of existing companies and Secretarial
                  Services.
                </li>
                <li>
                  Foreign investment proposal and direct investment; Advising
                  and facilitate overseas companies in opening office or
                  acquiring business or assets in Tanzania.
                </li>
                <li>Assist Corporate finance.</li>
                <li>
                  Assets and properties acquisition and disposal to local
                  investors.
                </li>
                <li>
                  Development and growth: we successfully assist businesses in
                  launching multiple developments on time and within budget.
                </li>
                <li>Shareholders Agreement/ Joint ventures agreement;</li>
                <li>
                  Settle partnership disputes in a cost-effective and timely
                  manner.
                </li>
              </ul>
            </div>
          </div>
          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              2.Labour, Employment and Immigration Law
            </div>
            <div align="justify">
              Our Labour, employment and immigration practices encompass all
              aspects of employment and immigration relations in Tanzania.
            </div>
            <div className="py-2 px-4 rounded-xl my-4 bg-yellow-500  text-left text-gray-700">
              Our philosophy around Labour is to focus on doing the right thing,
              and not just focus on the law. This approach assists clients to
              have less labor disputes and have a higher success rate, should
              cases be taken to the Labour Tribunals or the High Court. Our
              services include;
            </div>

            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  legal advice in this field is based on a pro-active approach
                  to minimize and manage any potential of risk and possible
                  litigation affecting clients’ human resources (HR). We advise
                  based on offences against the employer includes; fraud,
                  corruption, theft syndicates, governance and regulatory issues
                  on health and safety, data protection, privacy, information
                  technology, employee assistance based on illness, injury or
                  disability, vicarious liability, restraints of trade, unlawful
                  competition and team poaching, prohibited grounds, equal pay,
                  recruitment and selection, medical testing, harassment, and
                  employment equity.
                </li>
                <li>
                  Preparation, audit and reviews of employment policies and
                  contracts; such as drafting of employment contracts, review of
                  terms of employment, confidentiality agreements, termination
                  of employment contracts, assist employment claims (CMA and
                  High Court of Tanzania).
                </li>
                <li>
                  Providing counsel on complex HR structuring to maximize
                  financial objectives and minimize exposure to litigation –
                  including layoff strategies, plant shutdowns, executive
                  compensation, non-competition, confidentiality and similar
                  agreements and issues surrounding the enforceability and
                  advisability of collective bargaining agreements with
                  individual employees.
                </li>
                <li>
                  Assessment of existing HR systems within the organization,
                  reviewing existing HR policies, development of HR policies and
                  procedures.
                </li>
                <li>
                  Monitoring legal development and providing clients with ad hoc
                  advice on compliance matters and regular updates to a
                  Compliance Matrix we prepare for each client.
                </li>
                <li>
                  Advising and representing parties in dispute resolution on
                  matters based on disputes like unfair labour practice, unfair
                  dismissals, discrimination and conflict, arising out of the
                  employment relationship we assist based on mediation,
                  arbitration and litigation.
                </li>
                <li>
                  Developing or reviewing job descriptions for all employees.
                </li>
                <li>
                  Compliance with all labor and employment laws and regulations.
                </li>
              </ul>
            </div>
          </div>
          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              3.General & Specialized Litigation
            </div>

            <div className="py-2 px-4 rounded-xl bg-yellow-500 my-4 text-left text-gray-700">
              We provide a professional and efficient legal service, whether in
              a court room, arbitration/ tribunal hearing or other dispute
              resolution forum. We boast a strong team of Attorneys with both
              excellent litigation skills as well as expertise in the field. Our
              team guarantees proper advisory and representation in civil
              litigation and arbitration, both in civil and commercial aspects.
            </div>
            <div align="justify">
              We ensure laser-sharp approach to issues while providing
              sufficient counsel-client attention and our every requisite
              resource to each issue.
              <br />
              <br />
              We have extensive experience on advising large scale corporates
              including class action cases. We are renown for our ability to
              represent clients on their most critical civil litigation and
              arbitration issues. The firm can rapidly assemble a focused,
              integrated and efficient team to address all important aspects of
              a client’s problem and to handle numerous cases in multiple
              forums, including international disputes. We specialized but not
              limited to;
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>Criminal litigations.</li>
                <li>Alternative Dispute Resolutions (ADR).</li>
                <li>Commercial litigations.</li>
                <li>Dispute risk management.</li>
                <li>Employment disputes.</li>
                <li>Financial market regulation and litigations.</li>
                <li>Financial services litigations.</li>
                <li>Insurance litigations and arbitration.</li>
                <li>Intellectual property litigations.</li>
                <li>Trust and estate litigations.</li>
                <li>Tax litigations and dispute resolutions.</li>
                <li>Securities and shareholder litigations.</li>
                <li>Real estate litigations and dispute resolutions.</li>
                <li>Pensions and benefits disputes.</li>
                <li>Probate litigations.</li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              4.Betting, Gambling & Gaming
            </div>
            <div align="justify">
              Betting, gaming and gambling industries are experiencing explosive
              expansion in the United Republic of Tanzania and around the world.
              With this rapid growth come both opportunities and challenges.
              <br />
              <br />
              Msando Law Office has been at the forefront of these developments,
              helping to pave the path for I-gaming and sports-betting. We have
              been involved in some of the most significant regulatory
              developments and largest transactions in the space;
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  We advise on internet gambling operators, social gaming
                  companies, and traditional gambling companies interested in
                  exploring the internet space on the array of legislative and
                  regulatory developments impacting this growing industry.
                </li>
                <li>
                  We also represent service providers such as payment
                  processors, marketing affiliates, software providers, and
                  others.
                </li>
                <li>
                  Assist on getting licenses, organizational structure, and of
                  course, safety measures and taxes.
                </li>
                <li>
                  Advising and assisting international companies intended to
                  invest in the Tanzanian gaming industry.
                </li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              5.Probate & Administration of Estate
            </div>
            <div align="justify">
              Our law firm offers full-service probate and trust administration
              services and we will provide as much, or as little, guidance as
              you need to ensure all of your legal duties are satisfied.
              <br />
              <br />
              The probate and trust administration process can at times be
              complicated. There is typically a myriad of paperwork, financial
              accounts, insurance policies and retirement plans, as well as real
              and personal property to deal with. Paying special attention to
              beneficiary designations and how assets are titled is crucial
              during this time, and Msando Law Office can help ensure the estate
              is properly administered.
            </div>
            <div className="py-2 px-4 rounded-xl bg-yellow-500 my-4 text-left text-gray-700">
              In the absence of a Will or Trust, property titled in the
              decedent’s individual name will be settled under state intestacy
              laws. As the overseer of a decedent’s estate, the personal
              representative or trustee has a duty to settle and the duties of a
              personal representative or trustee include but are not limited to:
            </div>
            <div align="justify">
              <ul className="px-4 list-disc">
                <li>
                  Petition the appropriate court for authority to settle the
                  estate.
                </li>
                <li>Take possession or control of estate assets.</li>
                <li>
                  Determine who inherits estate assets and at what value or
                  percentage.
                </li>
                <li>Value or appraise assets.</li>
                <li>Prepare an inventory of property of the estate.</li>
                <li>
                  Manage, protect and preserve the estate until distribution or
                  liquidation.
                </li>
                <li>Notify and pay applicable creditors</li>
                <li>File the decedent’s final tax return</li>
                <li>Pay estate taxes, if any, and,</li>
                <li>Distribute the estate.</li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              6. Intellectual Property - Trademark| Copyright| Patent.
            </div>
            <div align="justify">
              We provide a comprehensive service that enables us to put together
              the appropriate package for each individual client. Our legal
              professionals have the expertise you need to help protect and
              further your business interests. Our services include;
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  We manage disputes and litigation relating to trademarks,
                  passing off, copyright infringements, patents, database
                  rights, and design rights including trademark licensing.
                </li>
                <li>
                  Registration Renewal of trade or service marks, copyrights,
                  patents and designs.
                </li>
                <li>Corporate and Legal Advisory.</li>
                <li>Restoration of rights.</li>
                <li>Competition and IP opinions.</li>
                <li>Amendment of Claims and Specifications.</li>
                <li>
                  Assisting clients in obtaining and protecting domain names
                  and.
                </li>
                <li>
                  Infringement and Passing-off action. Counterfeit Product
                  Investigation or Seizure.
                </li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              7. Real Estate and Conveyancing.
            </div>
            <div align="justify">
              The firm advises on a wide range of transactions involving the
              ownership and development of land by local and overseas
              purchasers, including:
            </div>

            <div align="justify">
              <ul className="list-disc px-4">
                <li>Purchase of Property, - Sale of Property.</li>
                <li>Lease & Renting.</li>
                <li>
                  Subdivisions, Surrender of Titles, Extension of Title Tenures.
                </li>
                <li>Procuring of Building Permits.</li>
                <li>
                  Due Diligence On Properties for Clients or Third Parties.
                </li>
                <li> Procuring of Derivative Rights for Clients.</li>
                <li>
                  Compliance Including Payment of Land Rents, Property Taxes,
                  And
                </li>
                <li>Mortgage and Other Modes of Creating Security.</li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">8. Taxation</div>
            <div align="justify">
              Understanding that Tax is the foremost concern for prosperity or
              otherwise of the businesses. Our firm provides the best services
              range from the tax aspects of corporate transactions to financial
              structuring and project finance matters to the tax implications of
              employment decisions. We also handle tax litigation and dispute
              resolution on behalf of our clients.
            </div>
            <div className="py-2 px-4 rounded-xl my-4 bg-yellow-500  text-left text-gray-700">
              Having the best tax team on board, at Msando Law Firm, we believe
              on all-out compliance as a tool against obstructive tax disputes.
              Nevertheless, we are contented of our ability to negotiate with
              the Tanzanian tax officials to ensure efficient determination of
              any arising tax issues on behalf of our clients.
            </div>
            <div align="justify">
              Our clients include leading corporates, retail and investment
              banks, financial institutions and high net-worth individuals in
              the countries in which we operate; at Msando Law office we assist
              our clients with the following;
            </div>
            <br />
            <div align="justify">
              <ul className="list-disc px-4">
                <li>Ad hoc tax advisory services on all type of taxes.</li>
                <li>Effective tax planning services.</li>
                <li>Customs and Excise duties.</li>
                <li>Value Added Tax.</li>
                <li>
                  Representation on correspondences with the Tanzania Revenue
                  Authority.
                </li>
                <li>Professional tax due diligence and tax health check.</li>
                <li>Tax compliance services. and</li>
                <li>
                  Represent clients in Tax Litigation and tax disputes before
                  appropriate courts including the Board, Tax Appeals Tribunal,
                  Income Tax Revenue Appeals and the Court of Appeal.
                </li>
              </ul>
            </div>
            <br />
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">9. Insurance Law</div>
            <div align="justify">
              Insurance is a system designed to protect an entity or institution
              or individual in the event of exposure to financial losses caused
              by an accident. Insurance is really helpful in some cases.
            </div>
            <div className="py-2 px-4 rounded-xl my-4  bg-yellow-500 text-left text-gray-700">
              Msando Law Office supervise all issues and claims arising from the
              various disputes relating to life, fire, demolition and vehicle
              insurance, as well as liability insurance and raise all the issues
              related to insurance with the competent judicial authorities and
              the representation of the agents in this regard.
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  Its principle is based on paying a premium amount in order to
                  return to the previous situation that was existed before
                  incurring a loss. Due to the rapid growth to the pace of
                  economic life, insurance law has divided into many branches
                  dealing with many different sectors.
                </li>
                <li>
                  Reviewed reinsurance agreements for normal layered treaties,
                  and facultative reinsurance for a private insurance company.
                </li>
                <li>Compensation for inconvenience and related expenses.</li>
                <li>
                  We provide legal consultations on insurance issues. managing
                  and filing insurance entity claims to a company or individual.
                </li>
                <li>Assist insurance loss adjustment.</li>
                <li>Interest on the unpaid contractual benefits.</li>
                <li>
                  Monitoring insurance services providers to ensure they carry
                  out activities in accordance with laws, rules and regulations
                  in safe and sound manner.
                </li>
                <li>Preparation making insurance policy.</li>
                <li>Contractual benefits owed by the insurance company.</li>
                <li>
                  development and growth on stability of the sector and solvency
                  of the insurance companies.
                </li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              10. Banking & Finance
            </div>
            <div align="justify">
              We provide a comprehensive service that enables us to put together
              the appropriate package for each individual client.
            </div>
            <div className="py-2 px-4 rounded-xl my-4  bg-yellow-500 text-left text-gray-700">
              Msando law office regularly advise a broad range of clients on all
              aspects in regulatory work as it pertains to banking regulation
              and financial services, including advising domestic and foreign
              banks, fund managers, portfolio managers, credit providers,
              pension funds, pension fund administrators, medical aid schemes
              and medical aid administrators, stock brokers and more recently,
              digital platform financial service providers. Our services range
              from
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  Setup of financial institutions, registration and obtaining of
                  licenses for banks and financial institutions.
                </li>
                <li>
                  Assisting Auditors in reviewing Annual Audits on a legal
                  perspective.
                </li>
                <li>
                  Financial advisory covering the full spectrum of financial
                  products, including but not limited to Vetting of foreign
                  agreements for conformity with Tanzanian laws, Asset Finance,
                  Project Finance, drawing of lending documentation, Perfection
                  of securities, and Insolvency and Restructuring of Debt,
                  Syndicated Lending and Perfection of Securities.
                </li>
                <li>
                  Investigate in establishing the propriety and legal bank
                  operations.
                </li>
                <li>Secured lending and drawing of lending documentation.</li>
                <li>
                  Ensure prompt and effective results when handling client’s
                  finance and banking transactions in Tanzania’s rapidly growing
                  financial sector.
                </li>
                <li>
                  legal updates to Banks and Financial Institutions on legal
                  changes.
                </li>
                <li>Security perfection and audits.</li>
                <li>
                  Dealing with complex transactions for borrowers, lenders,
                  issuers, lead managers, trustees, debt collectors, syndicated
                  lending, assignments, international trade and finance.
                </li>
                <li>
                  Representing banks and financial institutions in litigation
                  and arbitration proceedings.
                </li>
                <li>Security audit services.</li>
                <li>Loan Recovery and.</li>
                <li>Regulatory Compliance.</li>
                <li>Legal Recoveries and Insolvency.</li>
              </ul>
            </div>
          </div>

          <div className="h-10 w-1" />

          <div
            className=" text-yellow-600 px-20 py-16"
            style={{
              background: "#0e1438",
            }}
          >
            <div className="bold text-3xl text-left mb-4">
              11. MINING, ENERGY AND UTILITY
            </div>
            <div align="justify">
              We have significant experience in delivering legal services and
              expertise to mining, energy and utilities sectors.
            </div>
            <div className="py-2 px-4 rounded-xl my-4  bg-yellow-500 text-left text-gray-700">
              We are providing specialist knowledge in consulting with clients
              to build effective organizations, innovate and grow, reduce costs,
              and manage risk and regulations. We also Implement a proposal plan
              for relocation, resettlement and compensation.
            </div>
            <div align="justify">
              <ul className="list-disc px-4">
                <li>
                  Identify and advising improvement areas, defining and
                  prioritizing remedial actions, we offer full range of services
                  to identify problems, gain cooperation, develop viable
                  solutions and implement them;.
                </li>
                <li>
                  Advise on the regulatory regime, compliance issue, taxation,
                  employment and land issues related to mining, energy and
                  utilities sector.
                </li>
                <li>
                  Assist in obtaining prospecting licenses, special mining
                  licenses, mining licenses and primary mining licenses and
                  Other licenses include Processing Licence, Smelting Licences,
                  Refinery Licences and licenses for dealing with minerals
                  (Broker’s and Dealer’s Licences).
                </li>
                <li>
                  We focus on addressing specific needs and challenges
                  encountered by mining companies, investors and stake holders.
                </li>
                <li>
                  Mining Dispute resolution; Assist in audit level presentations
                  negotiations and settlement discussions in order to resolve
                  disputes efficiently.
                </li>
                <li>
                  Tax dispute resolution to issues related to mining by managing
                  responding to investigations, audits and examinations
                  initiated by the Tanzania revenue authority.
                </li>
                <li>
                  We build up a strong defense strategy to ensure that tax is a
                  risk that is managed effectively.
                </li>
                <li>
                  Insolvency solutions, crisis and stakeholder management,
                  financial restructuring, operational restructuring,
                  operational restructuring.
                </li>
                <li>
                  Managing risks, understanding of the company’s strategic
                  objectives and risk tolerance, we assist in develop risk
                  management solutions that minimize hazard, resolve uncertainty
                  and maximize opportunity.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;

import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Services = () => {
  const data = [
    {
      heading: "Real Estate & Conveyancing",
      thumbnail: "../areas/1.jpeg",
      title: "Real Estate & Conveyancing",
      content:
        "Our team’s meticulous attention to detail ensure that all commercial contracts are drafted to protect our clients’ interests and reduce exposure to future disputes. Our clients are assisted to identify, manage and mitigate risks of corporate non-compliance.",
      link: "/services",
    },
    {
      heading: "Alternate Dispute Resolution",
      thumbnail: "../areas/2.jpeg",
      title: "Alternate Dispute Resolution",
      content:
        "Our philosophy around labor is to focus on doing the right thing, and not just focus on the law. This approach assists clients to have less labor disputes and have a higher success rate, should cases be taken to the Labor Tribunals or the High Court.",
      link: "/services",
    },
    {
      heading: "Corporate & Commercial",
      thumbnail: "../areas/3.jpeg",
      title: "Corporate & Commercial",
      content:
        "We have extensive experience on advising large scale corporates including class action cases. We are renown for our ability to represent clients on their most critical civil litigation and arbitration issues. The firm can rapidly assemble a focused, integrated and efficient team to address all important aspects of a client’s problem and to handle numerous cases in multiple forums, including international disputes.",
      link: "/services",
    },
    {
      heading: "Banking & Finance",
      thumbnail: "../areas/4.jpeg",
      title: "Banking & Finance",
      content:
        "We advise internet gambling operators, social gaming companies, and traditional gambling companies interested in exploring the internet space on the array of legislative and regulatory developments impacting this growing industry. We also represent service providers such as payment processors, marketing affiliates, software providers, and others.",
      link: "/services",
    },
    {
      heading: "Employment & Immigration",
      thumbnail: "../areas/5.jpeg",
      title: "Employment & Immigration",
      content:
        "In the absence of a Will or Trust, property titled in the decedent’s individual name will be settled under state intestacy laws. As the overseer of a decedent’s estate, the personal representative or trustee has a duty to settle and the duties of a personal representative or trustee .",
      link: "/services",
    },
    {
      heading: "Regulatory Compliance",
      thumbnail: "../areas/6.jpeg",
      title: "Regulatory Compliance",
      content:
        "Our clients come from diverse sectors of the economy and we have represented them in a myriad of transactions; corporate commercial, infrastructure, insurance, real estate, mergers and acquisitions, banking and finance, corporate restructuring, insolvency, mining, energy and telecommunications, mediation, arbitration and litigation.",
      link: "/services",
    },
  ];
  return (
    <div className="w-screen  flex flex-col items-center ">
      <div
        className=" bold w-full flex flex-col items-center justify-center lg:text-4xl text-3xl pt-8 text-black"
        data-aos="fade-up"
      >
        PRACTICE AREAS
        <div
          className="bg-blue-900 mt-3"
          style={{
            height: 1,
            width: 100,
          }}
        />
      </div>
      <div className="  w-full grid grid-cols-1 md:grid-cols-3 mb-20 mt-10">
        {data.map((item) => (
          <div
            style={{
              height: 300,
              backgroundImage: `url(${item.thumbnail})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              borderWidth: 1,
            }}
            className="relative group cursor-pointer border-white flex justify-center  items-end text-gray-500"
          >
            <div className="flex justify-center items-center h-full text-2xl bg-black bg-opacity-20 w-full bold text-white">
              {item.title}
            </div>
            <div className="w-full backdrop-blur absolute bottom-0 flex flex-col justify-around  overflow-hidden group-hover:h-full h-0 transition-all duration-500 bg-white ">
              <div className="text-3xl bold py-4 group-hover:translate-y-0 translate-y-20 transition-all delay-200 duration-500 text-left ml-3">
                {item.heading}
              </div>
              <div
                className="group-hover:translate-y-0 translate-y-20 transition-all delay-[300ms] duration-500 mx-3"
                align="justify"
              >
                {item.content}
              </div>
              <Link
                to={`${item.link}`}
                className="w-full flex justify-start items-center p-4 group-hover:translate-y-0 translate-y-20 transition-all delay-[400ms] duration-500"
              >
                <div>Read More</div>
                <div className="flex items-center  ml-1">
                  <BsArrowRight />
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const ContainerContent = ({
  thumbnail,
  round,
  color,
  title1,
  content,
  id,
  link1,
}) => {
  AOS.init({
    duration: 800,
  });

  return (
    <div className=" h-screen w-screen relative regular" id={id}>
      <div className=" w-full h-full">
        <div
          style={{
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            height: "100%",
          }}
        />
      </div>
      <div
        className="w-screen h-screen  absolute top-0  flex justify-center items-center"
        style={{
          background: `linear-gradient(to right, ${color}, rgba(0,0,0,.7), rgba(0,0,0,0) )`,
          zIndex: 2,
        }}
      >
        <div className="md:w-3/4 w-full flex flex-wrap justify-between">
          <div className="md:w-1/3 w-full flex justify-center flex-col items-start mx-10 text-left ">
            <div className="lg:text-5xl text-3xl font-bold bold text-white bold text-left ">
              {title1}
            </div>
            <div className="my-2 text-gray-100 " data-aos-delay="100">
              {content}
            </div>
            {round ? (
              <Link
                to={`${link1}`}
                data-aos-delay="200"
                className="border border-gray-400 px-4 hover:bg-blue-900 hover:border-blue-900 hover:text-white py-2 text-white "
              >
                Discover More
              </Link>
            ) : (
              <Link
                to={`${link1}`}
                data-aos-delay="200"
                className="border border-gray-400 px-4 hover:bg-blue-900 hover:border-blue-900 hover:text-white py-2 text-white "
              >
                Read More
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerContent;
